import * as React from "react"
import { Layout } from "../components"

// So like who you are, what you’re doing now and what you’ve done?
const AboutPage = () => (
  <Layout>
    <main className="post">
      <article>
        <h2 className="post-title">About</h2>
        <div className="post-content">
          <p>I am a driven problem solver. My curiosity prompts me to ask questions and understand how things are connected.</p>
          <p>This has led me to work on a variety projects that combine my interests in technology, music, community, and civics.</p>
          <h4>Some things I've done -</h4>
          <ul>
            <li>Eagle Scout</li>
            <li>Community and Organizational Development Peace Corps Volunteer - Albania</li>
            <li>Co-Founder - Nashville Music Tech</li>
            <li>Nashville Emerging Leaders Class of 2016</li>
            <li>Delivery Lead - Code For Nashville</li>
            <li>Moth Story Slam winner, Gand Slam competitor</li>
          </ul>
          <p>I'm currently consulting on application development using React, React-Native, and Django. In my spare time, I am building on the blockchain and supporting the open source community.</p>
          <p> I can be reached at hello@coreymakesrice.com.</p>
        </div>
      </article>
    </main>
  </Layout>
)

export default AboutPage
